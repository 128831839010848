const CommonStyles = {
    scrollStyle: {
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid lightgrey'
        }
    },
    centerDiv: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
    },
    height100: {
        height: "100vh"
    }

}

export default CommonStyles