import { Box, Button, Card, CardContent, FormControl, FormHelperText, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import Building from '../Images/building.jpg'
import { Fragment, memo, useEffect, useState } from 'react'
import loginCardStyles from '../Styles/LoginCard'
import axios from 'axios'
import Modal from './UI/Modal'
import TermsAndConditionsModal from './UI/TermsAndConditions/v1'
import UAParser from 'ua-parser-js';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomCircularProgress from './UI/progress/CustomCircularProgress'
import { useNavigate } from 'react-router-dom'

const LoginCardOTP = (props) => {
    const { csrfToken } = props;
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(null);
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOTP] = useState('');
    const [secureKey, setSecureKey] = useState(null);
    const [otpError, setOTPError] = useState(null);
    const [tenant, setTenant] = useState('');
    const [tenantError, setTenantError] = useState('');
    const [tenantToken, setTenantToken] = useState('');
    const [tenantList, setTenantList] = useState([]);

    const logo = `$2b$10$k21hF0spByIZ.qzh6z2wYO.svg`

    const theme = useTheme()

    const isMobileOrTab = useMediaQuery(theme.breakpoints.down('lg'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate()

    const [osType, setOsType] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [client, setClient] = useState('')

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        setOsType(result.os?.name)
        setDeviceType(result.device?.type)
    }, [])

    useEffect(() => {
        if (isMobileOrTab) {
            setClient('pwa')
        } else {
            setClient('web')
        }
    }, [osType, isMobileOrTab, deviceType, isSmallScreen])

    // const [validUser, setValidUser] = useState({
    //     isSSOEnabled: false,
    //     isValidUser: false
    // })

    // const [invalidUserError, setInvalidUserError] = useState('')

    const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        setOsType(result.os?.name)
        setDeviceType(result.device?.type)
        //TODO : REMOVE console.log(result.device?.type)
    }, [])


    useEffect(() => {
        // if (osType) {
        //     //TODO : REMOVE console.log(osType)
        //     if ((osType?.toLowerCase()?.includes('windows') || osType?.toLowerCase()?.includes('mac') || osType?.toLowerCase()?.includes('linux')) && !isMobileOrTab && (deviceType === "desktop" || !deviceType)) {
        //         setClient('web')
        //     }
        //     else if ((osType?.toLowerCase()?.includes('ios') || osType?.toLowerCase()?.includes('android')) && isMobileOrTab && (deviceType === "mobile" || deviceType === "tablet" || isSmallScreen)) {
        //         setClient('pwa')
        //     } else {
        //         setClient('invalid')
        //     }

        // }
        //TODO : Above device identification library is giving incorrect results - so using old implementation code
        if (isMobileOrTab) {
            setClient('pwa')
        } else {
            setClient('web')
        }
    }, [osType, isMobileOrTab, deviceType, isSmallScreen])
    //TODO : REMOVE console.log({ client })

    const handleChange = (value, data) => {
        setPhone(value);
        // Extract country code and phone number
        const countryCode = data.dialCode;
        const phoneNumber = value.slice(countryCode?.length);
        setCountryCode(countryCode);
        setPhoneNumber(phoneNumber);
        setPhoneError(null);
    };

    const getOTP = async () => {
        try {
            // const response = await axios.get('${process.env.REACT_APP_URL}um/v1/vendor/auth/login/otp', { headers: { "x-csrf-token": csrfToken }, withCredentials: true })
            const response = await axios.post(`${process.env.REACT_APP_URL}um/v1/vendor/auth/login/otp`,
                { countryCallingCode: `+${countryCode}`, mobile: phoneNumber }, {
                headers: { "x-csrf-token": csrfToken },
                withCredentials: true
            })
            if (response?.status === 201) {
                setSecureKey(response?.data?.secureKey);
            }
        } catch (error) {
            setPhoneError(error?.response?.data?.message)
        }
    }

    const handleOTPChange = (e) => {
        setOTP(e.target.value);
        setOTPError('');
    }

    const handleTenantChange = (e) => {
        setTenant(e.target.value);
        setTenantError('');
    }

    // Validation to ensure the OTP is not empty and has at least 4 characters
    const validateOTP = async () => {
        if (!otp) {
            setOTPError("OTP is required.");
            return
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}um/v1/vendor/auth/login/otp/verify`,
                { secureKey, value: otp }, { headers: { "x-csrf-token": csrfToken }, withCredentials: true })
            if (response?.status === 200) {
                setTenantToken(response?.data?.tempToken)
                getTenants(response?.data?.tempToken)
            } else {
                setOTPError('Something went wrong!! Please try again')
            }
        } catch (error) {
            setOTPError(error?.response?.data?.message)
        }
    };

    const getTenants = async (tenantToken) => {
        // if (!tenant) {
        //     setTenantError("Tenant is required.");
        //     return;
        // } 
        try {
            // const response = await axios.get('${process.env.REACT_APP_URL}um/v1/vendor/auth/login/tenant-options', { headers: { "x-csrf-token": csrfToken, "Authorization": `Bearer ${tenantToken}` }, withCredentials: true })
            const response = await axios.get(`${process.env.REACT_APP_URL}um/v1/vendor/auth/login/tenant-options`,
                { headers: { "x-csrf-token": csrfToken, "Authorization": tenantToken }, withCredentials: true })
            if (response?.status === 200) {
                setTenantList(response?.data)
            } else {
                setTenantError('Something went wrong!! Please try again')
            }
        } catch (error) {
            setTenantError(error?.response?.data?.message)
        }

    }

    const handleLogin = async () => {
        if (!tenant) {
            setTenantError("Tenant is required.");
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}um/v1/vendor/auth/login/tenant/login?clientType=${client}`, { _id: tenant }, {
                headers: {
                    "x-csrf-token": csrfToken,
                    "Authorization": tenantToken
                }, withCredentials: true
            })
            if (response?.status === 200) {
                const { redirectUrl, exchange_token, exchange_id, role } = response.data

                // if (role === 'staff') {
                //     navigate('/', { state: { error: 'Login not allowed. Please contact admin' } })
                // }

                if (!redirectUrl || !exchange_token || !exchange_id) {
                    setTenantError('Something went wrong. Please try again')
                } else {
                    window.location.replace(`${redirectUrl}?exchange_token=${exchange_token}&exchange_id=${exchange_id}`)
                }
            }
        }
        catch (error) {
            setTenantError(error?.response?.data?.message)
        }
    }

    const handleOpenTermsAndConditionsModal = () => {
        setOpenTermsAndConditionsModal(true)
    }

    const handleCloseTermsAndConditionsModal = () => {
        setOpenTermsAndConditionsModal(false)
    }

    return (
        <Box sx={loginCardStyles.root}>
            <Card sx={loginCardStyles.card}>
                <Box sx={{ display: 'flex' }}>
                    <CardContent sx={{ ...loginCardStyles.cardContent, width: isMobileOrTab ? '80dvw' : '550px', maxHeight: isMobileOrTab ? '100dvh' : '550px', }}>
                        <>
                            <img src={`${process.env.REACT_APP_AWS_URL}${logo}`} alt="Company Logo" style={{ height: '30px', objectFit: 'contain', alignSelf: 'flex-start' }} />
                            <Typography sx={{ fontSize: '32px', mt: '40px', mb: '24px' }}>
                                {secureKey ? tenantToken ? 'Select Tenant from List' : 'Enter OTP to continue' : 'Enter your phone to login'}
                            </Typography>
                            <Box height={'300px'}>
                                {!secureKey && <Fragment>
                                    <Typography sx={loginCardStyles.loginTypography}>Login</Typography>
                                    <div>
                                        <PhoneInput
                                            value={phone}
                                            country={"in"}
                                            onChange={handleChange}
                                            inputStyle={{ width: "300px" }}
                                            buttonStyle={{ backgroundColor: "lightgrey" }}
                                            dropdownStyle={{ maxWidth: "300px" }}
                                            autoFormat={false}
                                        />
                                        {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
                                    </div>
                                </Fragment>}
                                {
                                    (secureKey && !tenantToken) && <>
                                        <Typography sx={loginCardStyles.loginTypography}>Enter OTP</Typography>
                                        <div style={{ marginTop: '8px' }}>
                                            <TextField
                                                sx={{ width: '300px' }}
                                                size='small'
                                                label="OTP"
                                                value={otp}
                                                onChange={handleOTPChange}
                                                error={!!otpError}
                                                helperText={otpError}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    (secureKey && tenantToken) && <Box sx={{ width: '300px', marginTop: '8px' }}>
                                        {tenantList.length > 0 ? <FormControl fullWidth size='small' error={!!tenantError}>
                                            <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={tenant}
                                                label="Tenant"
                                                onChange={handleTenantChange}
                                            >
                                                {tenantList && tenantList.map(({ _id, name }) =>
                                                    <MenuItem key={_id} value={_id}>{name}</MenuItem>)
                                                }
                                            </Select>
                                            {tenantError && <FormHelperText>{tenantError}</FormHelperText>}
                                        </FormControl> : <CustomCircularProgress />
                                        }
                                    </Box>
                                }
                            </Box>
                            <Box >
                                <Stack spacing={2} mb={'10px'}>
                                    <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540} component={Link} href='/'>Login as Tenant</Typography>
                                    {/* <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540}>By proceeding, you acknowledge that you use your organisation's email, your organisation may have rights to access and manage your data and account.</Typography>
                                    <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540}>By clicking {(validUser.isValidUser && !(validUser.isSSOEnabled)) ? `Login` : `Continue`}, you agree to these <Link sx={{ cursor: 'pointer' }} onClick={handleOpenTermsAndConditionsModal}>terms of use</Link> and allow nhance to get your user and tenant details.</Typography> */}
                                </Stack>
                                <Button
                                    sx={loginCardStyles.button}
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleLogin}
                                    onClick={secureKey ? tenantToken ? handleLogin : validateOTP : getOTP}
                                >
                                    {secureKey ? tenantToken ? 'submit' : 'submit otp' : 'get otp'}
                                </Button>
                            </Box>
                        </>
                    </CardContent>
                    {(!isMobileOrTab && client !== "invalid") && <img src={Building} alt='' width={'550px'} />}
                </Box>
                <Modal
                    modalOpen={openTermsAndConditionsModal}
                    closeModal={handleCloseTermsAndConditionsModal}
                >
                    <TermsAndConditionsModal />
                </Modal>
            </Card>
        </Box>
    )
}

export default memo(LoginCardOTP)
