import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const CustomCircularProgress = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress width='100%' />
        </Box>
    )
}

export default CustomCircularProgress