import React from 'react'
import { Box, IconButton, Modal as MuiModal, Paper, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ModalStyles from '../../Styles/UI/ModalStyles'

const Modal = (props) => {
    const { closeModal, modalOpen, modalWidth, title, children, height, sx } = props

    return (
        <MuiModal open={modalOpen} onClose={closeModal}>
            <Paper sx={{ ...ModalStyles.paperContainer, width: modalWidth || ModalStyles.paperContainer.width, height: height || 'auto', ...sx, }}>
                <Box sx={{ ...ModalStyles.boxContainer }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>{title}</Typography>
                    <IconButton onClick={closeModal}>
                        <CloseOutlinedIcon sx={{ color: 'grey' }} />
                    </IconButton>
                </Box>
                <Box sx={{ m: 2 }}>
                    {children}
                </Box>
            </Paper>
        </MuiModal>
    )
}

export default Modal