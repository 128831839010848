const TermsAndConditionsStyles = {
    mainHeading: {
        fontWeight: 600,
        pt: 2,
        pb: 1,
        fontSize: '24px'
    },
    content: {
        fontSize: '16px',
    }
}

export default TermsAndConditionsStyles