import axios from 'axios'

const GET_CSRF = async (updateCsrfFunc) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_URL}um/v1/csrf`, { withCredentials: true })
        if (response?.status === 200) {
            localStorage.setItem('csrfToken', response?.data?.csrf_token)
            if (updateCsrfFunc) updateCsrfFunc(response?.data?.csrf_token)
        }
    } catch (error) {
        console.log(error)
    }
}

export default GET_CSRF