const ModalStyles = {
    paperContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '80%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 24,
        p: 2,
    },
    boxContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}
export default ModalStyles