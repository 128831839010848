import { Box, Button, Card, CardContent, Link, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import Building from '../Images/building.jpg'
import { useEffect, useState } from 'react'
import loginCardStyles from '../Styles/LoginCard'
import axios from 'axios'
import isEmail from 'validator/lib/isEmail'
import Modal from './UI/Modal'
import TermsAndConditionsModal from './UI/TermsAndConditions/v1'
import UAParser from 'ua-parser-js';
import GET_CSRF from '../Utils/fetchCsrfToken'

const LoginCard = (props) => {
    const { loggedoutEmail, csrfToken, setCsrfToken } = props

    const logo = `$2b$10$k21hF0spByIZ.qzh6z2wYO.svg`

    const theme = useTheme()

    const isMobileOrTab = useMediaQuery(theme.breakpoints.down('lg'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [osType, setOsType] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [client, setClient] = useState('')

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        setOsType(result.os?.name)
        setDeviceType(result.device?.type)
        //TODO : REMOVE console.log(result.device?.type)
    }, [])


    useEffect(() => {
        // if (osType) {
        //     //TODO : REMOVE console.log(osType)
        //     if ((osType?.toLowerCase()?.includes('windows') || osType?.toLowerCase()?.includes('mac') || osType?.toLowerCase()?.includes('linux')) && !isMobileOrTab && (deviceType === "desktop" || !deviceType)) {
        //         setClient('web')
        //     }
        //     else if ((osType?.toLowerCase()?.includes('ios') || osType?.toLowerCase()?.includes('android')) && isMobileOrTab && (deviceType === "mobile" || deviceType === "tablet" || isSmallScreen)) {
        //         setClient('pwa')
        //     } else {
        //         setClient('invalid')
        //     }

        // }
        //TODO : Above device identification library is giving incorrect results - so using old implementation code
        if (isMobileOrTab) {
            setClient('pwa')
        } else {
            setClient('web')
        }
    }, [osType, isMobileOrTab, deviceType, isSmallScreen])
    //TODO : REMOVE console.log({ client })


    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')

    const [validUser, setValidUser] = useState({
        isSSOEnabled: false,
        isValidUser: false
    })

    const [invalidUserError, setInvalidUserError] = useState('')

    const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)

    // const handleOpenTermsAndConditionsModal = () => {
    //     setOpenTermsAndConditionsModal(true)
    // }

    const handleCloseTermsAndConditionsModal = () => {
        setOpenTermsAndConditionsModal(false)
    }

    useEffect(() => {
        if (loggedoutEmail && isEmail(loggedoutEmail)) {
            setEmail(loggedoutEmail)
        }
    }, [loggedoutEmail])

    const handleEmailPasswordChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value)
        }
        else if (e.target.name === "password") {
            setPassword(e.target.value)
        }
    }

    const verifyMail = async () => {
        try {
            if (email?.trim()?.length === 0) {
                setInvalidUserError('Email is Required')
            }
            else if (!isEmail(email)) {
                setInvalidUserError('Invalid Email Address')
            }
            else {
                setInvalidUserError('')
                const response = await axios.post(`${process.env.REACT_APP_URL}um/v1/user/verify/email`, { email }, { headers: { "x-csrf-token": csrfToken }, withCredentials: true })
                if (response?.status === 200) {
                    setValidUser(response?.data)
                    if (response?.data?.isSSOEnabled) {
                        window.location.replace(`${process.env.REACT_APP_URL}um/v1/user/auth/login/sso?email=${email}&client_type=${client}`)
                    }
                    else if (response?.data?.message) {
                        setInvalidUserError(response?.data?.message)
                    }
                }
            }
        } catch (error) {
            if (error?.response?.status === 400 && error?.response?.data?.error === 'Invalid csrf token') GET_CSRF(setCsrfToken)
            console.log(error)
        }
    }

    const handleLogin = async () => {
        try {
            if (email?.trim()?.length === 0) {
                setInvalidUserError('Email is Required')
            }
            else if (!isEmail(email)) {
                setInvalidUserError('Invalid Email Address')
            }
            else if (password?.trim()?.length === 0) {
                setInvalidUserError('Password is Required')
            }
            else if (password?.trim()?.length < 6) {
                setInvalidUserError('Password should be more than 6 characters')
            }
            else if (password?.trim()?.length > 128) {
                setInvalidUserError('Password should be less than 128 characters')
            }
            else {
                setInvalidUserError('')
                const response = await axios.post(`${process.env.REACT_APP_URL}um/v1/user/auth/login/basic?clientType=${client}`, { email, password }, { headers: { "x-csrf-token": csrfToken }, withCredentials: true })
                if (response?.status === 200) {

                    const { redirectUrl, exchange_token, exchange_id } = response.data

                    if (!redirectUrl || !exchange_token || !exchange_id) {
                        setInvalidUserError('Something went wrong. Please try again')
                    } else {
                        window.location.replace(`${redirectUrl}?exchange_token=${exchange_token}&exchange_id=${exchange_id}`)
                    }
                }
            }
        } catch (error) {
            if (error.response?.status === 401) {
                setInvalidUserError(error.response?.data?.message)
            } else if (error?.response?.status === 400 && error?.response?.data?.error === 'Invalid csrf token') GET_CSRF(setCsrfToken)
            console.log(error)
        }
    }

    const EnterKeySubmit = (event) => {
        if (event.key === 'Enter') {
            (validUser.isValidUser && !(validUser.isSSOEnabled)) ? handleLogin() : verifyMail()
        }
    }

    return (
        <Box sx={loginCardStyles.root}>

            <Card sx={loginCardStyles.card}>
                <Box sx={{ display: 'flex' }}>

                    <CardContent sx={{ ...loginCardStyles.cardContent, width: isMobileOrTab ? '80dvw' : '550px', maxHeight: isMobileOrTab ? '100dvh' : '550px', }}>
                        {
                            client === "invalid" ? (
                                <Typography sx={{ color: 'orange', textAlign: 'center' }}>
                                    Access to this application is not allowed on your current device.
                                </Typography>
                            ) : (
                                <>
                                    <img src={`${process.env.REACT_APP_AWS_URL}${logo}`} alt="Company Logo" style={{ height: '30px', objectFit: 'contain', alignSelf: 'flex-start' }} />
                                    <Typography sx={{ fontSize: '32px', mt: '40px', mb: '24px' }}>Enter your email, to login</Typography>
                                    <Box height={'300px'}>
                                        {/* <Typography sx={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>
                                            Access to this application is not allowed on your current device.
                                        </Typography> */}
                                        <Typography sx={loginCardStyles.loginTypography}>Login</Typography>
                                        <TextField
                                            sx={{
                                                ...loginCardStyles.textField,
                                                '& input::placeholder': {
                                                    fontSize: '18px',
                                                },
                                            }}
                                            placeholder="Enter your email id"
                                            size='small'
                                            variant="outlined"
                                            name='email'
                                            value={email}
                                            width='70%'
                                            onChange={handleEmailPasswordChange}
                                            onKeyDown={EnterKeySubmit}
                                            id='email'
                                        />
                                        {
                                            (validUser.isValidUser && !(validUser.isSSOEnabled)) && <TextField
                                                sx={{
                                                    pr: 10,
                                                    width: '100%'
                                                }}
                                                placeholder="Enter your password"
                                                size='small'
                                                variant="outlined"
                                                type="password"
                                                name='password'
                                                value={password}
                                                onChange={handleEmailPasswordChange}
                                                onKeyDown={EnterKeySubmit}
                                                id='password'
                                            />
                                        }
                                        {invalidUserError && <Typography sx={loginCardStyles.invalidUserError}>{invalidUserError}</Typography>}
                                    </Box>
                                    <Box >
                                        <Stack spacing={2} mb={'10px'}>
                                            <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540} component={Link} href='/vendor'>Login as Vendor</Typography>
                                            {/* <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540}>By proceeding, you acknowledge that you use your organisation's email, your organisation may have rights to access and manage your data and account.</Typography>
                                            <Typography width={isMobileOrTab ? '100%' : '400px'} fontSize={'12px'} fontWeight={540}>By clicking {(validUser.isValidUser && !(validUser.isSSOEnabled)) ? `Login` : `Continue`}, you agree to these <Link sx={{ cursor: 'pointer' }} onClick={handleOpenTermsAndConditionsModal}>terms of use</Link> and allow nhance to get your user and tenant details.</Typography> */}
                                        </Stack>
                                        <Button
                                            sx={loginCardStyles.button}
                                            variant="contained"
                                            color="primary"
                                            onClick={(validUser.isValidUser && !(validUser.isSSOEnabled)) ? handleLogin : verifyMail}
                                        >
                                            {(validUser.isValidUser && !(validUser.isSSOEnabled)) ? `Login` : `Continue`}
                                        </Button>
                                    </Box>
                                </>
                            )
                        }


                    </CardContent>
                    {(!isMobileOrTab && client !== "invalid") && <img src={Building} alt='' width={'550px'} />}
                </Box>
                <Modal
                    modalOpen={openTermsAndConditionsModal}
                    closeModal={handleCloseTermsAndConditionsModal}
                // title="Terms of Use"
                >
                    <TermsAndConditionsModal />
                </Modal>
            </Card>

        </Box>
    )
}

export default LoginCard